import React from 'react'

import "../styles/global.scss"

import Seo from './seo'
import TopBar from './top-bar'
import Header from './header'
import Footer from './footer'

import logo from "../../static/images/logo.svg"

// const menuItems = [
//   "about",
//   "pillars",
//   "testimonials",
//   "longevity sessions",
//   "contact us"
// ]

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        <Seo />
        <TopBar />
        <Header logo={logo}/>
        <main>{children}</main>
        <Footer />
      </>
    )
  }
}

export default Template
