import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { slugify } from "./helpers"

import logo from "../../static/images/footer/logo-mono-white.svg"
import bg from "../../static/images/footer/bg.svg"

const Footer = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        menuItems
                        contactEmail
                    }
                }
            }
        `
    )

    // const title = site.siteMetadata?.title
    const menu = site.siteMetadata?.menuItems
    // const email = site.siteMetadata?.contactEmail
    const year = new Date().getFullYear()

    return (
        <div className="footer" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="menu">
                    <img src={logo} alt="logo" className="logo" />
                    <div className="navigation">
                        <div className="navigation__item">
                            <Link
                                to="/"
                                activeClassName="active"
                                aria-label="homepage"
                            >
                                Home
                            </Link>
                        </div>
                        {menu.map((item) => (
                            <div
                                className="navigation__item"
                                key={slugify(item)}
                            >
                                <Link
                                    to={slugify(item)}
                                    activeClassName="active"
                                    aria-label={item}
                                >
                                    {item}
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="contact">
                    <b>Contact Us</b>
                    <p>
                        e-mail:{" "}
                        <a
                            href="mailto:info@longevitylabnyc.com"
                            aria-label="Our email"
                        >
                            info@longevitylabnyc.com
                        </a>
                    </p>
                    <p>
                        phone:{" "}
                        <a href="tel:914-506-1155" aria-label="Our phone">
                            Ola Fleming 914-506-1155
                        </a>
                    </p>
                </div>
            </div>
            <div className="container bottom-bar">
                <p>
                    All rights reserved. {year}&nbsp;&#169;&nbsp;Longevity
                    Lab&#174;
                </p>
                <p className="crafty-tag">
                    Designed by{" "}
                    <a href="https://craftystudio.pl">craftystudio.pl</a>
                </p>
            </div>
        </div>
    )
}
export default Footer
