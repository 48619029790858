import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Icon from "./ui/icon"

import { slugify, useViewport } from "./helpers"

import open from "../../static/icons/menu-open.svg"
import close from "../../static/icons/menu-close.svg"

const Navigation = ({ menuItems, email }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const { width } = useViewport()
    const breakpoint = 1100

    const openMenu = () => {
        isMobile && setIsOpen(!isOpen)
    }

    useEffect(() => {
        width < breakpoint ? setIsMobile(true) : setIsMobile(false)
    }, [width])

    useEffect(() => {
        width > breakpoint ? setIsOpen(true) : setIsOpen(false)
    }, [width])

    return (
        <>
            <nav role="navigation" aria-label="Main">
                {isOpen && (
                    <>
                        <ul
                            className={`navigation ${isMobile ? "mobile" : ""}`}
                        >
                            {menuItems.map((item) => (
                                <li
                                    className="navigation__item"
                                    key={slugify(item)}
                                >
                                    <Link
                                        to={slugify(item)}
                                        activeClassName="active"
                                        aria-label={item}
                                        onClick={openMenu}
                                        tabIndex="0"
                                    >
                                        {item}
                                    </Link>
                                </li>
                            ))}
                            <li className="navigation__item a__button">
                                <a
                                    href={`mailto:${email}`}
                                    activeClassName="active"
                                    aria-label="Contact Longevity Lab"
                                    onClick={openMenu}
                                >
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </>
                )}
                {isMobile && (
                    <button className="hamburger" onClick={openMenu}>
                        menu{" "}
                        {isOpen ? (
                            <Icon src={close} name="Close menu" />
                        ) : (
                            <Icon src={open} name="Open menu" />
                        )}
                    </button>
                )}
            </nav>
        </>
    )
}

export default Navigation
