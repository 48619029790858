import React from "react";

export const slugify = text => {
  const sluged = text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
  return "/" + sluged + "/"

}
  export const useViewport = () => {

  const windowWidth = typeof window === 'undefined' ? undefined : window.innerWidth
      
    const [width, setWidth] = React.useState(windowWidth);
    
    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
  
    // Return the width so we can use it in our components
    return { width };
  }
