import React from "react"
import { Link } from "gatsby"

const Icon = ({ src, name, link }) => {
    return (
        <Link className="icon-link" to={link} ariaLabel={name}>
            <img src={src} alt={name} />
        </Link>
    )
}

export default Icon
