import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import phone from "../../static/icons/phone-dark.svg"
import facebook from "../../static/icons/facebook-dark.svg"
import instagram from "../../static/icons/instagram-dark.svg"

const Icon = ({ icon, link, alt }) => {
    return (
        <a className="icon-link" href={link}>
            <img className="icon" src={icon} alt={alt} />
        </a>
    )
}

const facebookLink =
    "https://www.facebook.com/Longevity_lab_nyc-275507406301981/"
const instagramLink = "http://instagram.com/longevity_lab_nyc"

const TopBar = () => {
    const msg = useStaticQuery(
        graphql`
            query Message {
                allContentfulTopBarMessage {
                    nodes {
                        message
                    }
                }
            }
        `
    )

    return (
        <div className="top-bar">
            <div className="container">
                {msg.allContentfulTopBarMessage.nodes[0].message && (
                    <div className="message">
                        {msg.allContentfulTopBarMessage.nodes[0].message}
                    </div>
                )}
                <div className="contact-info">
                    <div className="phone">
                        <a href="tel:9145061155" aria-label="phone number">
                            <img
                                className="icon"
                                src={phone}
                                alt="telephone icon"
                            />{" "}
                            914-506-1155
                        </a>
                    </div>
                    <div className="social-icons">
                        <Icon
                            icon={facebook}
                            link={facebookLink}
                            alt="facebook icon"
                        />
                        <Icon
                            icon={instagram}
                            link={instagramLink}
                            alt="instagram icon"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar
